.main {
  background: #222a36;
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 4%;
  flex-direction: column;
  gap: 40px;
}

.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 5.2rem;
  line-height: 120%;
  letter-spacing: 0.03rem;
  color: #ffffff;
  margin-bottom: 4%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.projects {
  display: flex;
  flex-direction: row;
  margin: 0% 2% 4% 2%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 36px;
}

@media (max-width: 872px) {
  .heading {
    margin-top: 3%;
  }
}

@media (max-width: 575px) {
  .heading {
    font-size: 5rem;
  }
  .main {
    padding-top: 10%;
  }
}

@media (max-width: 530px) {
  .heading {
    font-size: 3.4rem;
  }
  .main {
    padding-top: 10%;
  }
}
