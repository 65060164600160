.main {
  background-color: #222a36;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.left {
  width: 50%;
  height: 100%;
}

.right {
  width: 50%;
  background-image: url("../../../../public/myPic.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.left-main {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  gap: 4%;
  z-index: 100;
}

.left-heading {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-size: 6rem;
  line-height: 120%;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.left-about {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 180%;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 30%;
}

.left-about span {
  color: white;
}

.contact-button {
  position: absolute;
  right: 3%;
  top: 2%;
  cursor: pointer;
}

@media (max-width: 1080px) {
  .left-heading {
    font-size: 5rem;
  }
}

@media (max-width: 800px) {
  .left-heading {
    font-size: 4.2rem;
  }
}
@media (max-width: 690px) {
  .left-heading {
    font-size: 3rem;
  }
}
@media (max-width: 600px) {
  .right {
    display: none;
  }
  .left {
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.66)),
      url("../../../../public/myPic.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  .left-main {
    /* margin-left: 5%; */
    gap: 7%;
  }
  .left-heading {
    font-size: 4rem;
    /* margin-bottom: 4%; */
  }
  .contact-button {
    display: none;
  }
  .left-heading {
    position: absolute;
    top: 8.5%;
    left: 7%;
  }
  .left-about {
    position: absolute;
    top: 64%;
    right: 5%;
    margin-right: 0%;
    width: 50%;
  }
  .left-button {
    position: absolute;
    bottom: 2%;
    left: 31%;
  }
}

@media (max-width: 480px) {
  .left-about {
    top: 64%;
    right: 5%;
    font-size: 1rem;
  }
  .left-heading {
    font-size: 3.5rem;
  }
  .left-button {
    bottom: 1.5%;
    left: 27%;
  }
}

@media (max-width: 400px) {
  .left-about {
    top: 63%;
    font-size: 0.95rem;
  }
}
