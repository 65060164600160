.main {
  width: fit-content;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
}

.main img {
  width: 90%;
}

.main span {
  font-family: "Noto Sans";
  font-style: bold;
  font-weight: 800;
  font-size: 20px;
  line-height: 180%;
  letter-spacing: 0.08rem;
  color: #c4c4c4;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 400px) {
  .main {
    margin-bottom: 10%;
  }
}
