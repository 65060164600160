.main {
  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  padding-top: 2%;
  padding-bottom: 2%;
  flex-wrap: wrap;
}

@media (max-width: 400px) {
  .main {
    padding-top: 15%;
    justify-content: space-evenly;
  }
}
