.main {
  min-height: 60vh;
  background: #000000;
  display: flex;
  flex-direction: row;
  padding-top: 6%;
  font-family: "Poppins";
  font-style: normal;
  justify-content: space-around;
  overflow: hidden;
}

.left {
  font-weight: 700;
  font-size: 6.5rem;
  line-height: 120%;
  color: #ffffff;
  width: 50%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  gap: 1%;
  margin-right: 4%;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 6.5%;
  /* margin-left: 3%; */
}

.right {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 27px;
  letter-spacing: -0.25px;
  color: #fffcf5;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 5%;
  margin-right: 6%;
  width: 100%;
  margin-left: 10%;
}
.connect-form-inputs {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 500px;
}
.each-input {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  /* background-color: red; */
}
.each-input input,
textarea {
  background: #454442;
  width: 100%;
  height: 25px;
  padding: 10px;
  outline: none;
  color: white;
  border: none;
  font-size: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: -0.25px;
}
.each-input input::placeholder,
textarea::placeholder {
  color: #9d9690;
}
.each-input input[type="text"]:focus,
textarea:focus {
  border: 1px solid #d3902a;
}
.connect-form-submit {
  width: fit-content;
}

.red {
  color: red;
}
.message {
  height: 100px;
}

@media (max-width: 1150px) {
  .left {
    font-size: 6rem;
  }
}
@media (max-width: 1080px) {
  .left {
    padding-top: 7%;
    font-size: 5rem;
  }
}
@media (max-width: 890px) {
  .left {
    padding-top: 10%;
    font-size: 4.2rem;
  }
}

@media (max-width: 750px) {
  .main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }
  .left {
    padding-top: 1%;
    padding-left: 0%;
    font-size: 3.2rem;
  }
  .icons {
    margin-top: 8%;
    padding-left: 13%;
  }
  .right {
    margin: 0;
    width: 70%;
    margin-bottom: 10%;
    margin-left: 6%;
  }
  .connect-form-submit {
    display: flex;
    margin-left: 33%;
  }
}

@media (max-width: 712px) {
  .left {
    width: 100%;
    align-items: center;
  }
  .icons {
    margin-top: 5%;
    margin-right: 17%;
  }
}

@media (max-width: 630px) {
  /* .left {
    padding-top: 10%;
    font-size: 2.8rem;
  } */
  .right {
    padding-top: 0;
    width: 60%;
    margin-right: 8%;
    font-size: 1rem;
  }
  .connect-form-submit {
    margin-left: 40%;
  }
  .each-input input::placeholder,
  textarea::placeholder {
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .left {
    padding-top: 10%;
    font-size: 2.8rem;
  }
  .icons {
    margin: 0;
    margin: auto;
    margin-left: 6%;
    margin-top: 8%;
  }
  .right {
    padding-top: 0;
    width: 80%;
    margin-right: 8%;
    font-size: 1rem;
  }
  .each-input input::placeholder,
  textarea::placeholder {
    font-size: 1rem;
  }
}
