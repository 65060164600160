.main {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 1%;
  border-bottom: 2px solid black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 180%;
  /* identical to box height, or 36px */

  text-align: center;
  letter-spacing: 0.3px;
  padding-bottom: 0.5%;
  color: rgba(255, 255, 255, 0.5);
  border-top: 2px solid rgb(30, 30, 30);
}

.highlighted {
  color: #fff;
  font-size: 18px;
  /* font-weight: 600; */
}
