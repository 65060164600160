.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 35px;
  gap: 10px;
  width: fit-content;
  background: #676cdb;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  color: #ffffff;
  cursor: pointer;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .main {
    padding: 12px 20px;
  }
}
