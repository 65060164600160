.container {
  max-width: 400px;
  border-radius: 20px;
  background: #d9d9d9;
  box-shadow: 2px 2px 100px rgba(0, 0, 0, 0.2);
  background: #363636;
  height: fit-content;
  padding-bottom: 20px;
  /* height: 530px; */
}
.header {
  max-width: 400px;
  min-height: 200px;
}
.header img {
  /* max-width: 100%;
  max-height: 100%; */
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0px 0px;
  aspect-ratio: 1.6;
}
.main {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  font-style: normal;
  line-height: 1.1rem;
  color: #cccccc;
}

.title {
  font-weight: 600;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04rem;
  margin-bottom: 6%;
}

.description {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 26px;
  display: flex;
  align-items: center;
  min-height: 110px;
  margin-bottom: 4%;
}

.tech-stack {
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 26px;
  display: flex;
  align-items: center;
  margin-bottom: 4%;
  flex-wrap: wrap;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.each-link a {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
  gap: 7px;
  background-color: #555555;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  text-decoration: none;
}

.link-disabled {
  cursor: not-allowed;
  opacity: 0.2;
}
